import React, { useState, useContext, forwardRef } from 'react';
import { useSessionStorage } from '@monash/portal-frontend-common';
import PageContainer from '../PageContainer';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import UpcomingEventDetail from './components/UpcomingEventDetail';
import UpcomingListingDesktop from './components/UpcomingListingDesktop';
import UpcomingListingMobile from './components/UpcomingListingMobile';
import { differenceInMonths } from 'date-fns';
import { useInterval } from 'hooks/use-interval';
import useLiveDate from 'hooks/use-live-date';
import LoadingWrapper from 'components/ui/loading-wrapper/LoadingWrapper';
import { eventsOnDay } from './utils/utils';
import { pageIds } from '../../../constants';
import { useResponsiveValue } from '@monash/portal-react';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { getHighestCourseStatus } from './utils/get-highest-course-status';
import { getMessage } from './utils/get-message';
import c from './upcoming.module.scss';

const Upcoming = forwardRef((_, ref) => {
  const [forceCodeError] = useSessionStorage('forceCodeError');

  if (forceCodeError) {
    const error = {};
    alert(error.throws.forcedCodeErrorIsOn);
  }

  const { userCourses, upcomingEvents, userEvents, loading, userEventsError } =
    useContext(DataContext);

  const { get } = useLiveDate();
  const [currentDate, setCurrentDate] = useState(get());
  const { UPCOMING } = pageIds;

  useInterval(() => {
    setCurrentDate(get());
  }, 5000);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showLocation, setShowLocation] = useSessionStorage(
    'showLocation',
    true
  );
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  const numMonthsApart = differenceInMonths(
    upcomingEvents?.[0]?.start?.time,
    currentDate
  );

  // banners
  const { pagesData, globalBannerShownPageId } = useContext(PageContext);
  const pageBannerShown = pagesData?.pageBanners?.[UPCOMING];
  const globalBannerShown = globalBannerShownPageId === UPCOMING;
  const bannerShown = pageBannerShown || globalBannerShown;

  const courseStatus = getHighestCourseStatus(userCourses);
  const message = getMessage({
    courseStatus,
    upcomingEvents,
    numMonthsApart,
    bannerShown: bannerShown ? UPCOMING : null,
  });

  if (!loading.userEvents && message) {
    return message;
  }

  // Upcoming events and active event details
  // if there are no events on today, then we have to adjust the given active index by -1 to correctly index the upcomingEvents array
  // b/c there is an additional event card that displays "No events" on the upcoming listing component
  // i.e. logically, the events data would be akin to something like:
  // upcoming listing: [no events card, event card 1, event card 2]
  // upcoming events: [event card 1, event card 2]
  const eventsOnToday = eventsOnDay(upcomingEvents, currentDate);
  const adjustedActiveIndex = eventsOnToday ? activeIndex : activeIndex - 1;
  const activeEvent = upcomingEvents[adjustedActiveIndex];
  const nextEvent = upcomingEvents[0];
  const loadingUserEvents = loading.userEvents;

  return (
    <>
      <div
        className={c.Today}
        ref={ref}
        data-banner-shown={bannerShown ? UPCOMING : null}
      >
        <PageContainer
          type="upcoming"
          pageId={UPCOMING}
          loading={loadingUserEvents}
        >
          {loadingUserEvents ? (
            <LoadingWrapper />
          ) : (
            <div
              className={c.wrapper}
              aria-label="Upcoming Events board"
              role="region"
            >
              {responsiveSize === 'L' ? (
                <>
                  <UpcomingListingDesktop
                    currentDate={currentDate}
                    events={upcomingEvents}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    showLocation={showLocation}
                    setShowLocation={setShowLocation}
                    userEventsError={userEventsError}
                  />

                  <UpcomingEventDetail
                    currentDate={currentDate}
                    event={activeEvent}
                    nextEvent={nextEvent}
                    allEvents={userEvents}
                    activeIndex={activeIndex}
                  />
                </>
              ) : (
                <UpcomingListingMobile
                  currentDate={currentDate}
                  events={upcomingEvents}
                  allEvents={userEvents}
                  userEventsError={userEventsError}
                />
              )}
            </div>
          )}
        </PageContainer>
      </div>
    </>
  );
});

export default Upcoming;
