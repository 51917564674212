import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import c from './assessments.module.scss';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import {
  ImpersonationContext,
  isObject,
  isStringEmpty,
} from '@monash/portal-frontend-common';
import { getDisplayDate } from 'components/utilities/user-events/get-display-date';
import { ExternalLink, Icon, LoadingIndicator } from '@monash/portal-react';
import { createPortal } from 'react-dom';
import ScheduleCard, {
  SCHEDULE_CARD_INSTANCE,
} from 'components/pages/schedule/schedule-card/ScheduleCard';
import classroom from './classroom.svg';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.svg';
import WidgetEncumbrance from '../../widget-container/widget-block/WidgetEncumbrance';
import WIDGET_ERROR_MESSAGE from '../../widget-container/widget-error/WidgetErrorMessage';
import { MISSING_SOME_ASSESSMENTS_ERROR } from 'constants/error-messages';
import Day from './day/Day';
import ScrollToElement from '../../widget-container/header/header-actions/scroll-to-element/ScrollToElement';
import { WidgetContext } from 'components/providers/WidgetProvider';
import EditAssessmentsFilter from './edit-assessments-filter/EditAssesmentsFilter';
import {
  getUnitCodesWithAssessments,
  injectFilterOptionsWithUnits,
  isFilterActive,
  passesStatusFilter,
  passesBasicStatusFilter,
  passesTypeFilter,
  passesUnitFilter,
} from './utils';
import isEqual from 'lodash.isequal';
import { getDisplayStatus } from 'components/utilities/user-events/render-status';
import AssessmentsFilterAction from './assessments-filter-action/AssessmentsFilterAction';
import { eventTypes } from 'constants/event-types';
import useContainerWidth, {
  BOX_SIZE_MODE,
  CONTAINER_WIDTH,
} from 'hooks/use-container-width';
import { FEATURE_FLAGS } from '../../../../../constants/features';
import DataVisibilityToggle from '../../widget-container/header/header-actions/data-visibility-toggle/DataVisibilityToggle';
import { useFeatureFlags } from 'hooks/use-feature-flags';

export const ASSESSMENTS_OPTION_KEY = {
  FILTER: 'filter',
  UNIT: 'unit',
  TYPE: 'type',
  STATUS: 'status',
};

export const ASSESSMENTS_TYPE_OPTION = {
  QUIZ: 'quiz',
  ASSIGNMENT: 'assignment',
  WORKSHOP: 'workshop',
  EXAM: 'exam',
};

export const ASSESSMENTS_STATUS_OPTION = {
  NOT_OPEN_YET: 'not_open_yet',
  NOT_SUBMITTED: 'not_submitted',
  SUBMITTED: 'submitted',
  GRADED: 'graded',
};

const FILTER_OPTIONS = [
  {
    key: ASSESSMENTS_OPTION_KEY.UNIT,
    name: 'Unit',
    options: [], // units are dynamic options and therefore are not initialised here
  },
  {
    key: ASSESSMENTS_OPTION_KEY.TYPE,
    name: 'Type',
    options: [
      { id: ASSESSMENTS_TYPE_OPTION.QUIZ, name: 'Quiz' },
      { id: ASSESSMENTS_TYPE_OPTION.ASSIGNMENT, name: 'Assignment' },
      { id: ASSESSMENTS_TYPE_OPTION.WORKSHOP, name: 'Workshop' },
      { id: ASSESSMENTS_TYPE_OPTION.EXAM, name: 'Exam' },
    ],
  },
  {
    key: ASSESSMENTS_OPTION_KEY.STATUS,
    name: 'Status',
    options: [
      {
        id: ASSESSMENTS_STATUS_OPTION.NOT_OPEN_YET,
        name: 'Not open yet',
      },
      {
        id: ASSESSMENTS_STATUS_OPTION.NOT_SUBMITTED,
        name: 'Not submitted',
      },
      {
        id: ASSESSMENTS_STATUS_OPTION.SUBMITTED,
        name: 'Submitted',
      },
      {
        id: ASSESSMENTS_STATUS_OPTION.GRADED,
        name: 'Graded',
      },
    ],
  },
];

const SCROLL_TO_TODAY_ID_SUFFIX = 'assessments-day-today';

const Assessments = ({
  data,
  updateData,
  onSelectedPage,
  setError,
  setFloatingError,
  widgetScrollContainerRef,
  widgetId,
  additionalOptions,
  setAdditionalOptions,
  escapeSettingActionsFocusRef,
}) => {
  const featureFlags = useFeatureFlags();
  const { userEvents, userEventsError, loading, currentDate } =
    useContext(DataContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { editAssessmentsFilterActiveWidget } = useContext(WidgetContext);

  const filterOptionData = data?.[ASSESSMENTS_OPTION_KEY.FILTER];
  const filterActive = isFilterActive(filterOptionData);
  const filterOptions = additionalOptions.find(
    (optModule) => optModule.key === ASSESSMENTS_OPTION_KEY.FILTER
  )?.options;

  // filter out options with non-existent frontend module and/or non-existent firestore data
  const validatedFilterOptions = Array.isArray(filterOptions)
    ? filterOptions.filter((optModule) => {
        return (
          isObject(optModule) && isObject(filterOptionData?.[optModule.key])
        );
      })
    : [];

  const unitsFilterOptionsLoaded = useRef(false);

  // units are dynamic, therefore we need logic to:
  // 1. initialise/sanitise units filter options data that is saved in firestore
  // 2. set the additional options that are displayed on the frontend
  const initUnitsFilterOptions = (userEvents) => {
    // 1. init/sanitise fs data
    if (filterOptionData === undefined) return;

    const unitCodesWithAssessments = getUnitCodesWithAssessments(userEvents);

    const savedUnitsOptions = isObject(
      filterOptionData[ASSESSMENTS_OPTION_KEY.UNIT]
    )
      ? filterOptionData[ASSESSMENTS_OPTION_KEY.UNIT]
      : {};

    const newUnitOptions = unitCodesWithAssessments.reduce(
      (acc, curr) => {
        const DEFAULT_VALUE = true;
        // if unit code is not saved in firestore, init it to default
        if (!Object.prototype.hasOwnProperty.call(savedUnitsOptions, curr)) {
          return {
            ...acc,
            [curr]: DEFAULT_VALUE,
          };
        }
        // if unit code is already saved in firestore, take the saved option
        return acc;
      },
      { ...savedUnitsOptions }
    );

    // if there are no assessment/exam errors,
    // remove any options that are not in the current list of unitCodesWithAssessments
    // so that firestore isn't storing unnecessary unit codes after a student completes a unit
    if (
      !(
        userEventsError?.missingAllAssessments ||
        userEventsError?.missingSomeAssessments ||
        userEventsError?.missingExams
      )
    ) {
      Object.keys(newUnitOptions).forEach((unitCode) => {
        if (!unitCodesWithAssessments.includes(unitCode)) {
          delete newUnitOptions[unitCode];
        }
      });
    }

    // if calculated options are different to saved options in firestore, override with calculated options
    if (!isEqual(newUnitOptions, savedUnitsOptions)) {
      updateData(ASSESSMENTS_OPTION_KEY.FILTER, {
        ...filterOptionData,
        [ASSESSMENTS_OPTION_KEY.UNIT]: newUnitOptions,
      });
    }

    // 2. set additional options to be displayed on frontend
    // update additional options for 'unit'
    setAdditionalOptions((a) => {
      const additionalOptions = a.map((optModule) => {
        // look for 'filter' option module
        if (optModule.key !== ASSESSMENTS_OPTION_KEY.FILTER) return optModule;

        // look inside 'filter' option module
        const filterOptModule = { ...optModule };

        // inject options for unit codes with assesssments
        filterOptModule.options = injectFilterOptionsWithUnits(
          filterOptModule.options,
          unitCodesWithAssessments
        );
        return filterOptModule;
      });
      return additionalOptions;
    });
  };

  // wait for widget data and userEvents to load before initialising units filter preferences in fs
  useEffect(() => {
    if (
      typeof data === 'object' &&
      !loading.userEvents &&
      unitsFilterOptionsLoaded.current === false
    ) {
      initUnitsFilterOptions(userEvents);
      unitsFilterOptionsLoaded.current = true;
    }
  }, [data, loading.userEvents, userEvents]);

  // handle widget size styling
  const { containerCallbackRef, size } = useContainerWidth({
    sizes: [
      { name: CONTAINER_WIDTH.SMALL, maxWidth: 360 },
      { name: CONTAINER_WIDTH.LARGE },
    ],
    boxSizeMode: BOX_SIZE_MODE.BORDER,
  });

  // map assessments by day
  const assessments = userEvents?.filter((e) => {
    if (!filterActive) {
      return e.eventKind === 'assessment' || e.eventType === eventTypes.EXAM;
    }

    // apply filters
    const unitCode = e.data?.unitCode;
    const type = e.eventType;
    const status = isStringEmpty(e.data?.status)
      ? null
      : getDisplayStatus({
          status: e.data?.status,
          currentDate,
          submissionStartDate: e.data?.submissionStartDate?.time,
        });

    return (
      (e.eventKind === 'assessment' || e.eventType === eventTypes.EXAM) &&
      passesUnitFilter(
        unitCode,
        filterOptionData[ASSESSMENTS_OPTION_KEY.UNIT]
      ) &&
      passesTypeFilter(type, filterOptionData[ASSESSMENTS_OPTION_KEY.TYPE]) &&
      (featureFlags.GRADES // TODO: when released, remove feature flag and just use passesStatusFilter, NOT passesBasicStatusFilter
        ? passesStatusFilter(
            status,
            e.data?.gradeFormatted,
            filterOptionData[ASSESSMENTS_OPTION_KEY.STATUS]
          )
        : passesBasicStatusFilter(
            status,
            filterOptionData[ASSESSMENTS_OPTION_KEY.STATUS]
          ))
    );
  });

  const currentDayDisplayDate = getDisplayDate(currentDate, currentDate);

  // assessment card (pop up)
  const [isAssessmentCardShown, setIsAssessmentCardShown] = useState(false);
  const [activeAssessmentRef, setActiveAssessmentRef] = useState(null);
  const [activeAssessmentIndex, setActiveAssessmentIndex] = useState(null);
  const [displayData, setDisplayData] = useState(null);

  const showAssessmentCard = (item, itemData, index) => {
    setIsAssessmentCardShown(true);
    setActiveAssessmentRef({ ...item });
    setActiveAssessmentIndex(index);
    setDisplayData(itemData);
  };

  // map assessments by day
  const mappedAssessments = assessments.reduce((acc, curr) => {
    const dd = getDisplayDate(currentDate, curr?.start.time);
    const yy = new Date(curr?.start.time).getFullYear();

    // find if the same day has already exist
    const sameDayIndex = acc.findIndex(
      (item) => item.displayDate === dd && item.year === yy
    );
    if (sameDayIndex !== -1) {
      //  if it already exist, add assessment to the day
      acc[sameDayIndex].assessments.push(curr);
      return [...acc];
    } else {
      // if it does't exist, create new day object and add assessment to the day
      return [
        ...acc,
        {
          displayDate: dd,
          year: yy,
          assessments: [curr],
          isToday: dd === currentDayDisplayDate,
          isUpcoming: curr?.start.time > currentDate,
        },
      ];
    }
  }, []);

  const appendCurrentDay = (days) => {
    // do nothing if current day already exists
    const currentDayAlreadyExists = days.find((day) => day.isToday);
    if (currentDayAlreadyExists) {
      return days;
    }

    // find the first upcoming day with assessments
    const firstUpcomingDayIndex = days.findIndex((day) => day.isUpcoming);
    const newDays = [...days];
    const currentDay = {
      displayDate: currentDayDisplayDate,
      year: new Date(currentDate).getFullYear(),
      assessments: null,
      isToday: true,
      isUpcoming: false,
    };
    // if none, append current day to end of the list
    if (firstUpcomingDayIndex === -1) {
      return [...newDays, currentDay];
    } else {
      // if yes, append current day before that
      newDays.splice(firstUpcomingDayIndex, 0, currentDay);
      return newDays;
    }
  };

  const mappedAssessmentsWithCurrentDay = appendCurrentDay(mappedAssessments);

  // refs
  const assessmentsRefs = useMemo(
    () => assessments?.map(() => React.createRef()),
    [userEvents]
  );
  const dayRefs = useMemo(
    () => mappedAssessmentsWithCurrentDay?.map(() => React.createRef()),
    [userEvents]
  );

  // auto scroll to today
  useEffect(() => {
    if (
      onSelectedPage &&
      dayRefs &&
      widgetScrollContainerRef?.current?.scrollTop === 0
    ) {
      const todayIndex = mappedAssessmentsWithCurrentDay.findIndex(
        (day) => day.isToday === true
      );
      const anchor =
        dayRefs[todayIndex]?.current?.getBoundingClientRect().top -
        widgetScrollContainerRef?.current?.getBoundingClientRect().top;
      widgetScrollContainerRef?.current?.scrollTo({ top: anchor });
    }
  }, [dayRefs, onSelectedPage]);

  // error handling
  useEffect(() => {
    if (userEventsError?.missingAllAssessments) {
      setError(WIDGET_ERROR_MESSAGE.ASSESSMENTS);
    }
    if (userEventsError?.missingSomeAssessments) {
      setFloatingError(MISSING_SOME_ASSESSMENTS_ERROR);
    }
  }, [userEventsError]);

  // encumbrance
  const encumbered =
    currentUser?.hasEncumbrances &&
    currentUser?.encumbranceEffectTypes?.includes('SUS_SRVC');
  if (encumbered) {
    return <WidgetEncumbrance />;
  }

  return (
    <div className={c.assessments} ref={containerCallbackRef}>
      {editAssessmentsFilterActiveWidget === widgetId ? (
        <EditAssessmentsFilter
          widgetId={widgetId}
          optionData={filterOptionData}
          updateData={updateData}
          escapeFocusRef={escapeSettingActionsFocusRef}
          filterOptions={validatedFilterOptions}
        />
      ) : null}
      {createPortal(
        <ScheduleCard
          instance={SCHEDULE_CARD_INSTANCE.ASSESSMENTS}
          shown={isAssessmentCardShown}
          setShown={setIsAssessmentCardShown}
          scheduleItemRef={activeAssessmentRef}
          displayData={displayData}
        />,
        document.body
      )}
      {!loading.userEvents && assessments?.length ? (
        <ul className={c.days}>
          {mappedAssessmentsWithCurrentDay?.map((day, i) => (
            <Day
              key={day.displayDate}
              ref={dayRefs[i]}
              day={day}
              widgetId={widgetId}
              isAssessmentCardShown={isAssessmentCardShown}
              activeAssessmentIndex={activeAssessmentIndex}
              showAssessmentCard={showAssessmentCard}
              assessments={assessments}
              assessmentsRefs={assessmentsRefs}
              currentDate={currentDate}
              scrollToTodaySuffix={SCROLL_TO_TODAY_ID_SUFFIX}
              size={size}
              visible={data?.visible}
            />
          ))}
        </ul>
      ) : null}
      {loading.userEvents ? <LoadingIndicator /> : null}
      {!loading.userEvents && !assessments?.length ? (
        <div className={c.noAssessments}>
          <img src={classroom} alt="" />
          <h3>
            {filterActive
              ? "Your selected filters don't match any assessments."
              : 'You have no upcoming assessments.'}
          </h3>
          <ExternalLink
            text="Open Moodle"
            link="https://learning.monash.edu"
            mode="card"
            variant="text"
          />
        </div>
      ) : null}
    </div>
  );
};

// TODO: once grades functionality released, rename this to "AssessmentsModule" and just export this
const AssessmentsModuleNew = {
  component: Assessments,
  name: 'Assessments',
  icon: WidgetIcon.Assessments,
  previewImage: preview,
  description: 'View your assessments',
  headerActions: [
    {
      component: DataVisibilityToggle,
      componentProps: {
        dataLabel: 'grades',
      },
    },
    {
      name: 'Filter',
      component: AssessmentsFilterAction,
      componentProps: {
        filterOptions: FILTER_OPTIONS,
      },
    },
    {
      name: 'Back to today',
      component: ScrollToElement,
      componentProps: {
        icon: Icon.CalendarBack,
        elementIdSuffix: SCROLL_TO_TODAY_ID_SUFFIX,
      },
    },
  ],
  additionalOptions: [
    {
      key: 'name',
      name: 'Name',
      editType: 'text',
      default: 'Assessments',
    },
    {
      key: ASSESSMENTS_OPTION_KEY.FILTER,
      name: 'Filters',
      editType: null,
      default: {
        [ASSESSMENTS_OPTION_KEY.UNIT]: {},
        [ASSESSMENTS_OPTION_KEY.TYPE]: {
          [ASSESSMENTS_TYPE_OPTION.QUIZ]: true,
          [ASSESSMENTS_TYPE_OPTION.ASSIGNMENT]: true,
          [ASSESSMENTS_TYPE_OPTION.WORKSHOP]: true,
          [ASSESSMENTS_TYPE_OPTION.EXAM]: true,
        },
        [ASSESSMENTS_OPTION_KEY.STATUS]: {
          [ASSESSMENTS_STATUS_OPTION.NOT_OPEN_YET]: true,
          [ASSESSMENTS_STATUS_OPTION.NOT_SUBMITTED]: true,
          [ASSESSMENTS_STATUS_OPTION.SUBMITTED]: true,
          [ASSESSMENTS_STATUS_OPTION.GRADED]: true,
        },
      },
      options: FILTER_OPTIONS,
    },
    {
      key: 'visible',
      default: true,
      editType: null,
    },
  ],
  additionalActions: [
    {
      action: 'editAssessmentsFilter',
      icon: <Icon.Filter size={20} />,
      text: 'Set filters',
      haspopup: 'dialog',
    },
  ],
};

// TODO: once grades functionality released, remove FILTER_OPTIONS_CURRENT
const FILTER_OPTIONS_CURRENT = [
  {
    key: ASSESSMENTS_OPTION_KEY.UNIT,
    name: 'Unit',
    options: [], // units are dynamic options and therefore are not initialised here
  },
  {
    key: ASSESSMENTS_OPTION_KEY.TYPE,
    name: 'Type',
    options: [
      { id: ASSESSMENTS_TYPE_OPTION.QUIZ, name: 'Quiz' },
      { id: ASSESSMENTS_TYPE_OPTION.ASSIGNMENT, name: 'Assignment' },
      { id: ASSESSMENTS_TYPE_OPTION.WORKSHOP, name: 'Workshop' },
      { id: ASSESSMENTS_TYPE_OPTION.EXAM, name: 'Exam' },
    ],
  },
  {
    key: ASSESSMENTS_OPTION_KEY.STATUS,
    name: 'Status',
    options: [
      {
        id: ASSESSMENTS_STATUS_OPTION.NOT_OPEN_YET,
        name: 'Not open yet',
      },
      {
        id: ASSESSMENTS_STATUS_OPTION.NOT_SUBMITTED,
        name: 'Not submitted',
      },
      {
        id: ASSESSMENTS_STATUS_OPTION.SUBMITTED,
        name: 'Submitted',
      },
    ],
  },
];

// TODO: once grades functionality released, remove this module and just export the "new" one
const AssessmentsModuleCurrent = {
  component: Assessments,
  name: 'Assessments',
  icon: WidgetIcon.Assessments,
  previewImage: preview,
  description: 'View your assessments',
  headerActions: [
    {
      name: 'Filter',
      component: AssessmentsFilterAction,
      componentProps: {
        filterOptions: FILTER_OPTIONS_CURRENT,
      },
    },
    {
      name: 'Back to today',
      component: ScrollToElement,
      componentProps: {
        icon: Icon.CalendarBack,
        elementIdSuffix: SCROLL_TO_TODAY_ID_SUFFIX,
      },
    },
  ],
  additionalOptions: [
    {
      key: 'name',
      name: 'Name',
      editType: 'text',
      default: 'Assessments',
    },
    {
      key: ASSESSMENTS_OPTION_KEY.FILTER,
      name: 'Filters',
      editType: null,
      default: {
        [ASSESSMENTS_OPTION_KEY.UNIT]: {},
        [ASSESSMENTS_OPTION_KEY.TYPE]: {
          [ASSESSMENTS_TYPE_OPTION.QUIZ]: true,
          [ASSESSMENTS_TYPE_OPTION.ASSIGNMENT]: true,
          [ASSESSMENTS_TYPE_OPTION.WORKSHOP]: true,
          [ASSESSMENTS_TYPE_OPTION.EXAM]: true,
        },
        [ASSESSMENTS_OPTION_KEY.STATUS]: {
          [ASSESSMENTS_STATUS_OPTION.NOT_OPEN_YET]: true,
          [ASSESSMENTS_STATUS_OPTION.NOT_SUBMITTED]: true,
          [ASSESSMENTS_STATUS_OPTION.SUBMITTED]: true,
        },
      },
      options: FILTER_OPTIONS_CURRENT,
    },
  ],
  additionalActions: [
    {
      action: 'editAssessmentsFilter',
      icon: <Icon.Filter size={20} />,
      text: 'Set filters',
      haspopup: 'dialog',
    },
  ],
};

// TODO: once grades functionality released, remove this temp logic and just export the
// "new" assessments module
const cachedFeatureFlags = sessionStorage.getItem('featureFlags');
const featureFlags = cachedFeatureFlags
  ? JSON.parse(cachedFeatureFlags)
  : FEATURE_FLAGS;

const AssessmentsModule = featureFlags?.GRADES
  ? AssessmentsModuleNew
  : AssessmentsModuleCurrent;

export default AssessmentsModule;
