import {
  SESSION_STORAGE_KEYS,
  useSessionStorage,
} from '@monash/portal-frontend-common';
import { SnackbarDispatcher } from './SnackbarDispatcher';
import c from './popup.module.scss';
import { DropdownWithValue, Switch } from '@monash/portal-react';
import classNames from 'classnames';

const Misc = () => {
  const [forceCodeError, setForceCodeError] = useSessionStorage(
    'forceCodeError',
    false
  );

  const [showGlobalBanner, setShowGlobalBanner] = useSessionStorage(
    'mock:showGlobalBanner'
  );

  const [numberOfBanners, setNumberOfBanners] = useSessionStorage(
    'mock:numberOfGlobalBanners',
    'One'
  );

  const [showPageBanners, setShowPageBanners] = useSessionStorage(
    SESSION_STORAGE_KEYS.MOCKS.SHOW_PAGE_BANNERS
  );

  const [showEncumbrances, setShowEncumbrances] = useSessionStorage(
    'mock:showEncumbrances'
  );

  const wrapperClasses = classNames(c.stack, c.tabWrapper);

  return (
    <div className={wrapperClasses}>
      <div>
        <div className={c.label}>Force code error</div>
        <Switch
          on={forceCodeError}
          onClick={() => {
            setForceCodeError(!forceCodeError);
          }}
        />
      </div>

      <hr />

      <div className={c.inputWrapper}>
        <div className={c.label}>Show global banner</div>
        <Switch
          on={showGlobalBanner}
          onClick={() => {
            setShowGlobalBanner(!showGlobalBanner);
          }}
        />

        <DropdownWithValue
          id="type"
          className="custom-class"
          ariaLabelledby="type"
          onChange={(value) => {
            setNumberOfBanners(value);
          }}
          value={numberOfBanners}
          data={['One', 'Many'].map((x, i) => {
            return { id: i, text: x, value: x };
          })}
        />
      </div>

      <hr />

      <div className={c.inputWrapper}>
        <div className={c.label}>Show page banners</div>
        <Switch
          on={showPageBanners}
          onClick={() => {
            setShowPageBanners(!showPageBanners);
          }}
        />
      </div>

      <hr />

      <div className={c.inputWrapper}>
        <div className={c.label}>Show encumbrances</div>
        <Switch
          on={showEncumbrances}
          onClick={() => {
            setShowEncumbrances(!showEncumbrances);
          }}
        />
      </div>

      <hr />

      <div className={c.inputWrapper}>
        <div className={c.label}>Snackbar</div>
        <SnackbarDispatcher />
      </div>
    </div>
  );
};

export default Misc;
