import { differenceInHours, format } from 'date-fns';

export const formatPublishDate = (currentDate, published) => {
  const publishDate = new Date(published);
  const distance = differenceInHours(currentDate, publishDate);
  if (distance < 24 && distance >= 0) {
    return `${distance}hr ago`;
  } else {
    return format(publishDate, 'd MMMM yyyy');
  }
};
