import { ImpersonationContext } from '@monash/portal-frontend-common';
import { widgetDirectory } from 'components/pages/custom/widgets/widgetDirectory';
import { useContext } from 'react';

const DISABLED_WIDGET_KEYS = {
  DEFAULT: [],
  MONASH_COLLEGE: [3, 5, 7], // 3 Jun 2024: Monash College doesn't need Assessments, GPA/WAM, Library
  INDONESIAN: [2],
  MALAYSIAN: [2, 5],
};

/**
 * Retrieves an "filtered" widget directory based on configs:
 * - disabledKeys: array of keys to remove from the directory
 *
 * Usage example:
 * This removes the results and news widgets from the directory:
 * getFilteredWidgetDirectory({disabledKeys = [5, 6]})
 *
 * @returns modified widgetDirectory object
 */
const getFilteredWidgetDirectory = ({
  disabledKeys = DISABLED_WIDGET_KEYS.DEFAULT,
} = {}) => {
  const directory = { ...widgetDirectory };
  if (!Array.isArray(disabledKeys) || disabledKeys.length === 0) {
    return directory;
  }
  // remove disabled entries and return
  disabledKeys.forEach((key) => {
    delete directory[key];
  });
  return directory;
};

/**
 * Hook that contains logic to determine the user's relevant widget directory
 * based on data from contexts/other places
 * @returns adjusted widget directory object, with keys = widget id, values = widget module
 */
const useFilteredWidgetDirectory = () => {
  const { currentUser } = useContext(ImpersonationContext);

  // default widget library
  let disabledWidgetKeys = DISABLED_WIDGET_KEYS.DEFAULT;

  // Indonesian widget library
  if (currentUser?.is?.Indonesian) {
    disabledWidgetKeys = DISABLED_WIDGET_KEYS.INDONESIAN;
  }

  // Monash College widget library
  if (currentUser?.is?.MonashCollege) {
    disabledWidgetKeys = DISABLED_WIDGET_KEYS.MONASH_COLLEGE;
  }

  // Malaysian widget library
  if (currentUser?.is?.Malaysian) {
    disabledWidgetKeys = DISABLED_WIDGET_KEYS.MALAYSIAN;
  }

  return getFilteredWidgetDirectory({ disabledKeys: disabledWidgetKeys });
};

export default useFilteredWidgetDirectory;
