import { Tabs } from '@monash/portal-react';
import { useSessionStorage } from '@monash/portal-frontend-common';
import { useState } from 'react';

import c from './popup.module.scss';
import FeatureToggles from './FeatureToggles';
import Misc from './Misc';
import { format } from 'date-fns';
import Data from './Data';

const Popup = ({ isShowing }) => {
  const [testDate] = useSessionStorage('testDate');
  const currentDate = testDate ? new Date(testDate) : new Date();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = ['Data', 'Features', 'Misc'];

  return (
    <div className={`${c.popupWrapper} ${!isShowing && c.isHidden}`}>
      <div className={c.popup}>
        <div className={c.testDate} aria-hidden="true">
          <b>Current date:</b> {format(currentDate, 'EEEE do MMMM y, h.mmaaa')}
        </div>

        <div className={c.tabs}>
          <Tabs
            tabs={tabs}
            selected={selectedTabIndex}
            onChange={(i) => {
              setSelectedTabIndex(i);
            }}
          />
        </div>

        {selectedTabIndex === 0 && <Data />}
        {selectedTabIndex === 1 && <FeatureToggles />}
        {selectedTabIndex === 2 && <Misc />}
      </div>
    </div>
  );
};

export default Popup;
